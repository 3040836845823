import type { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SWRConfig } from 'swr';
import { FormatSimple, Tolgee, TolgeeProvider, useTolgeeSSR } from '@tolgee/react';

import '@amf/shared/styles/main.scss';
import 'styles/main.scss';

const tolgee = Tolgee()
  .use(FormatSimple())
  .init({
    defaultLanguage: 'cz',
    apiKey: process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
    apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
    defaultNs: 'general',
    ns: ['general', 'olomouc'],
  });

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

async function swrFetcher(path: string) {
  const res = await fetch(path);
  return res.json();
}

function App({ Component, pageProps, staticData }: AppProps & { staticData: any }) {
  const router = useRouter();
  const ssrTolgee = useTolgeeSSR(tolgee, router.locale, staticData);
  return (
    <TolgeeProvider tolgee={ssrTolgee}>
      <SWRConfig
        value={{
          fetcher: swrFetcher,
        }}
      >
        <SimpleReactLightbox>
          <Component {...pageProps} />
        </SimpleReactLightbox>
      </SWRConfig>
    </TolgeeProvider>
  );
}

App.getInitialProps = async context => {
  const locale = context.router.locale;
  return {
    staticData: {
      [`${locale}:general`]: await import(
        `@amf/shared/translations/locales/general/${locale}.json`
      ),
      [`${locale}:olomouc`]: await import(
        `@amf/shared/translations/locales/olomouc/${locale}.json`
      ),
    },
  };
};

export default App;
